.account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92%;
    width: 100%;
    overflow-y: auto;
    background: linear-gradient(270deg, #860000, #013a6b);

    @media screen and (min-width: 1080px) {
        height: 100%;
    }
}

.account-panel {
    color: white;
    font-weight: 300;

    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 740px;
    padding: 20px;

    #active {
        border-bottom: 1px solid white;
    }

    .nav {
        display: flex !important;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 1em;
    }

    .heading {
        font-weight: 700;
        margin-top: 1em;
        padding: 10px 20px;
        transition: all 0.3s;
    }
    .heading:active {
        scale: 0.95;
    }

    .headingText {
        cursor: pointer;
    }

    .form {
        width: 70%;
    }

    .form-title {
        font-size: 1.5em;
        font-weight: 700;
        padding: 10px 20px;
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
        svg {
            font-size: 1em;
            margin-right: 0.2em;
        }
    }

    .form-item {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;

        input {
            width: 100%;
            font-size: 0.75em;
            font-family: inherit;
            padding: 10px 15px;
            border: 0.1px solid #2a2a2a;
            border-radius: 5px;
            background-color: #333333;
            color: white;
            box-sizing: border-box;

            @media screen and (min-width: 740px) {
                padding: 15px;
            }
            @media screen and (min-width: 1080px) {
                padding: 20px;
            }
        }
    }
    .divider {
        border: solid 0.5px grey;
        margin: 1em;
    }
}

.account-panel button {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #000;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    cursor: pointer;
    appearance: button;
    align-self: center;

    @media screen and (min-width: 740px) {
        padding: 15px;
    }
}

.account-panel button:hover {
    color: #dbdbdb;
}

.account-panel .red {
    background: #900000;
}
.account-panel .red:hover {
    background: #780000;
}

.account-panel .green {
    background: green;
}
.account-panel .green:hover {
    background: #006c00;
}

.account-panel .blue {
    background: #008cd2;
}
.user-container .blue:hover {
    background: #006da3;
}
