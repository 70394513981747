.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0000007a;
    border: solid 1px #21212179;
    border-radius: 5px;
    padding: 1em 0;

    textarea {
        width: 100%;
        height: 15em;
        font-size: 0.7em;
        font-family: inherit;
        padding: 1em;
        border: 0.1px solid #2a2a2a;
        border-radius: 5px;
        background-color: #333333;
        color: white;
        resize: none;
        overflow-y: scroll;
        box-sizing: border-box;
    }

    .spinner-container {
        height: 30px;
        position: relative;
        margin-top: 10px;
        padding: 5px;
    }
}
