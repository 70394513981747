.parlay-container {
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    height: 92%;
    width: 100%;
    background: linear-gradient(270deg, #860000, #013a6b);
    box-sizing: border-box;

    @media screen and (min-width: 1080px) {
        height: 100%;
        flex-direction: row;
        padding-top: 0;
    }

    .builder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 1em 0;
        padding: 1em 0;
        border-radius: 10px;
        background-color: #0000007a;

        @media screen and (min-width: 1080px) {
            height: 90%;
            width: 70%;
            max-width: 500px;
            margin: 0 1em;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5em;
            text-align: center;

            .label {
                margin-bottom: 0.5em;
                font-family: Roboto Condensed, Roboto, sans-serif;
            }
        }

        .name {
            font-family: inherit;
            color: white;
            padding: 0.65em 1.5em;
            border: 1px solid rgba(148, 148, 148, 0.3);
            border-radius: 32px;
            background: #333333;
            cursor: pointer;
            @media screen and (max-width: 540px) {
                padding: 0.77em 1.5em;
            }
            @media screen and (min-width: 2160px) {
                padding: 0.6em 1.5em;
            }
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-color: #0000007a;
            margin: 0 0.25em;
            height: 3em;
            border-radius: 32px;
            border-color: #808080d6;
            padding: 0.5em 1em;
            color: #e0e0e0;
            cursor: pointer;
            font-size: 1em;
            text-align-last: center;
        }

        .spinner-container {
            height: 30px;
            position: relative;
            margin-top: 10px;
            padding: 5px;
        }
        button {
            width: 100%;
            margin-top: 1em;
            padding: 10px;
            border: none;
            border-radius: 50px;
            color: #fff;
            font-size: 0.8em;
            font-weight: 700;
            cursor: pointer;
            appearance: button;
            align-self: center;

            @media screen and (min-width: 740px) {
                padding: 15px;
            }

            svg {
                margin-right: 0.5em;
            }
        }
        button:hover {
            color: #dbdbdb;
        }

        .button-content {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .content {
            display: flex;
        }

        .green {
            background: green;
        }
        .green:hover {
            background: #006c00;
        }

        .timestamp {
            color: grey;
            font-size: 0.75em;
            margin-top: 10px;
        }
    }

    .search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15em;
        max-width: 500px;

        .search-bar {
            width: 100%;
            z-index: 2;
            .input {
                font-family: inherit;
                color: white;
                padding: 1em 1.5em;
                border: 1px solid rgba(148, 148, 148, 0.3);
                border-radius: 50px;
                background: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(10px);
            }
        }
    }

    .message-container {
        background-color: #0000007a;
        border-radius: 10px;
        height: 100%;
        width: 90%;
        margin-bottom: 1em;

        @media screen and (min-width: 1080px) {
            height: calc(90% + 2em);
            margin-right: 1em;
            margin-bottom: 0;
        }

        .message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            color: gray;

            @media screen and (max-width: 540px) {
                font-size: 0.8em;
            }
        }
    }

    .parlay {
        display: flex;
        flex-direction: column;
        width: 90%;
        background-color: #0000007a;
        border-radius: 10px;
        margin-bottom: 1em;

        @media screen and (min-width: 1080px) {
            margin-right: 1em;
            max-height: calc(90% + 2em);
            overflow-y: scroll;
        }

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 5em;
            width: 100%;
            background-color: #000000a0;
            border-radius: 10px;

            .scope {
                display: flex;
                padding: 0.5em;
                border-radius: 10px;
                background-color: #2828289a;

                .scope-item {
                    margin: 0 0.5em;
                    padding: 0.5em 0.75em;
                    font-size: 0.8em;
                    font-weight: 700;
                    cursor: pointer;
                }
                .active {
                    background-color: #4c4c4c;
                    border-radius: 5px;
                }
            }
        }

        .line {
            display: flex;
            justify-content: space-between;
            margin: 1em;
            padding: 1em;
            @media screen and (max-width: 540px) {
                padding: 0.5em;
            }

            @media screen and (min-width: 720px) {
                font-size: 1.1em;
            }
            @media screen and (min-width: 1600px) {
                font-size: 1.2em;
            }
            @media screen and (min-width: 2160px) {
                font-size: 1.4em;
            }

            .left {
                display: flex;
                align-items: center;
            }

            .remove {
                margin-right: 1em;
                font-size: 1.5em;
                color: #ff3333;
                cursor: pointer;
                transition: all 0.5s;
            }
            .remove:hover {
                color: #b51a1a;
            }

            .info {
                text-align: start;
                @media screen and (max-width: 540px) {
                    font-size: 0.85em;
                }

                .name {
                    font-size: 1.2em;
                    font-weight: 700;
                }

                .stat {
                    color: #b7b6b6;
                    font-family: Roboto Condensed;
                    font-weight: 700;
                    font-size: 0.9em;
                }

                .value {
                    font-size: 0.9em;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
                .value svg {
                    cursor: pointer;
                    margin: 0 3px;
                    vertical-align: middle;
                }
                .disabled {
                    opacity: 50%;
                    cursor: default !important;
                }
            }

            .right {
                display: flex;
                align-items: center;
            }

            .summary {
                width: 7em;
                height: 4em;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;
                margin-left: 0.75em;
                font-weight: 700;
                font-size: 0.75em;
                color: #b7b6b6;
                @media screen and (max-width: 540px) {
                    font-size: 0.7em;
                }
                .green {
                    color: #1db303;
                }
                .yellow {
                    color: yellow;
                }
                .red {
                    color: #c90808;
                }
            }

            .indicator {
                height: 4em;
                width: 4em;
                @media screen and (max-width: 540px) {
                    font-size: 0.8em;
                    margin-right: 0;
                }
            }
        }
    }
}
