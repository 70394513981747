.App {
    text-align: center;
    font-family: Roboto, Arial;
    color: white;
    background-color: black;
    overflow: hidden;
    transition: all 0.3s;
    user-select: none;
    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}
img,
svg {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

button {
    transition: all 0.3s;
    font: inherit;
}
button:active {
    scale: 0.975;
}

@media screen and (min-width: 540px) {
    .App {
        font-size: 1.1rem;
    }
}
@media screen and (min-width: 1080px) {
    .App {
        font-size: 1.2rem;
    }
}
@media screen and (min-width: 2160px) {
    .App {
        font-size: 1.4rem;
    }
}

.progress-stepper {
    position: absolute;
    bottom: 12%;
    left: 1.5em;
    z-index: 2;
    height: 3em;
    width: 3em;
}

@media screen and (min-width: 1080px) {
    .progress-stepper {
        left: calc(12% + 1.5em);
        bottom: 5%;
    }
}

.shimmerBG {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.005) 8%,
        /* Subtle shimmer start */ rgba(255, 255, 255, 0.06) 18%,
        /* Darker shimmer stripe */ rgba(255, 255, 255, 0.005) 33%
            /* Subtle shimmer end */
    );
    background-size: 1200px 100%;
    background-color: transparent; /* Ensure it overlays the panel's background */
    position: relative; /* Required for proper overlay */
    overflow: hidden; /* Ensure it doesn't exceed panel boundaries */
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

/* Animations */

.tilt-in-fwd-tr {
    -webkit-animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-27 8:51:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg)
            translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
            skew(-35deg, 10deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0)
            skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg)
            translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px)
            skew(-35deg, 10deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0)
            skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}

.tilt-in-fwd-bl {
    -webkit-animation: tilt-in-fwd-bl 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: tilt-in-fwd-bl 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-27 9:2:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-bl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(-35deg)
            translate(-300px, 300px) skew(-35deg, 10deg);
        transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
            skew(-35deg, 10deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0)
            skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-fwd-bl {
    0% {
        -webkit-transform: rotateY(-20deg) rotateX(-35deg)
            translate(-300px, 300px) skew(-35deg, 10deg);
        transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px)
            skew(-35deg, 10deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0)
            skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}
.shake-horizontal {
    -webkit-animation: shake-horizontal 0.8s
        cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
        both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-27 9:32:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}
@keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}
.scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-27 19:9:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
.fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-6-28 3:16:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.puff-in-center {
    -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
        both;
    animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-11-22 14:0:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
