.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 92%;
    width: 100%;
    max-height: -webkit-fill-available;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;

    background: linear-gradient(270deg, #860000, #013a6b);

    @media screen and (min-width: 1080px) {
        height: 100%;
    }

    .header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.25em;
        margin-bottom: 0.5em;
        font-family: Bebas Neue, Roboto, Arial, Helvetica, sans-serif;
        font-size: 4em;

        .logo-image {
            margin-top: -12px;
            height: 1.25em;
        }
        img {
            width: 1.25em;
        }
    }

    .refresh {
        position: absolute;
        top: 1em;
        right: 1em;
        display: flex;
        align-items: center;
        font-family: Bebas Neue, Roboto, Arial, Helvetica, sans-serif;
        font-size: 1.75em;
        gap: 0.1em;

        .refresh-icon {
            padding-bottom: 3px;
        }
    }

    .streak {
        position: absolute;
        top: 2.25em;
        right: 1em;
        display: flex;
        align-items: center;
        font-family: Bebas Neue, Roboto, Arial, Helvetica, sans-serif;
        font-size: 1.75em;
        gap: 0.2em;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0 0.5em;
        box-sizing: border-box;
        @media screen and (min-width: 800px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (min-width: 1600px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .versus {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #0000007a;
            border-radius: 16px;
            width: 90%;
            padding: 1em;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1em;
            @media screen and (max-width: 540px) {
                padding: 1em 0.75em;
            }
            .category {
                font-family: Bebas Neue, Roboto, Arial, sans-serif;
                font-size: 1.5em;
                font-weight: 700;
                margin-bottom: 0.5em;
            }
            .panel {
                border-radius: 16px;
                display: flex;
                width: 100%;
                border-radius: 16px;
                overflow: hidden;

                .player {
                    position: relative;
                    width: 50%;
                    overflow: hidden;
                    transition: background-color 1s ease-in-out,
                        width 1s ease-in-out;
                    .name {
                        font-family: Roboto Condensed, Roboto, Arial;
                        font-size: 1.1em;
                        font-weight: 700;
                        padding-top: 1em;
                        padding-bottom: 0.5em;
                        white-space: nowrap;
                    }
                    .votes {
                        margin-bottom: 0.5em;
                        font-size: 1.2em;
                        font-family: Bebas Neue, Roboto Condensed, Roboto, Arial;
                    }
                    .name,
                    .votes,
                    .image {
                        position: relative;
                        z-index: 2;
                    }
                    .image {
                        height: 18.27vw;
                        @media screen and (min-width: 800px) {
                            height: 8.77vw;
                        }
                        @media screen and (min-width: 800px) {
                            height: 8.77vw;
                        }
                        @media screen and (min-width: 1600px) {
                            height: 6.01vw;
                        }
                        img {
                            vertical-align: bottom;
                            width: 25vw;
                            @media screen and (min-width: 800px) {
                                width: 12.5vw;
                            }
                            @media screen and (min-width: 1600px) {
                                width: 8.33vw;
                            }
                        }
                    }
                    .logoBG {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                        height: 200%;
                        opacity: 0.1;
                        pointer-events: none;
                    }
                }
            }
        }
        .blank {
            background-color: #0000007a;
            border-radius: 16px;
            height: calc((1.37 * 25vw) + 2.6em);
            width: 90%;
            padding: 1em;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1em;

            @media screen and (max-width: 540px) {
                padding: 1em 0.75em;
            }
            @media screen and (min-width: 800px) {
                height: calc((1.37 * 12.5vw) + 3em);
            }
            @media screen and (min-width: 1600px) {
                height: calc((1.37 * 8.33vw) + 3em);
            }
        }
    }
}
