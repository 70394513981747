.register-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0000007a;
    border: solid 1px #21212179;
    border-radius: 5px;
    height: 25em;
    padding: 1em 0;

    .spinner-container {
        height: 30px;
        position: relative;
        margin-top: 10px;
        padding: 10px;
    }
}
