.navbar-container {
    z-index: 10;

    height: 8%;
    min-height: 60px;
    width: 100%;
    background: linear-gradient(270deg, #860000, #013a6b);
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.6em;

    @media screen and (min-width: 1080px) {
        height: 100%;
        width: 12%;
        background: linear-gradient(0deg, #860000, #013a6b);
        font-size: 0.9em;
    }

    .nav {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: #00000059;

        @media screen and (min-width: 1080px) {
            flex-direction: column;
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            font-size: 2em;
            margin-bottom: 0.1em;
        }
    }
}
