.compare-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 92%;
    width: 100%;
    background: linear-gradient(270deg, #860000, #013a6b);

    @media screen and (min-width: 1080px) {
        height: 100%;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 1em 0;
    }

    .search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        max-width: 500px;

        .search-bar {
            width: 100%;
            z-index: 2;
            .input {
                font-family: inherit;
                color: white;
                padding: 1em 1.5em;
                border: 1px solid rgba(148, 148, 148, 0.3);
                border-radius: 50px;
                background: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(10px);
            }
        }
        .reload {
            margin-left: 0.5em;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .reload svg {
            cursor: pointer;
            transition: all 0.3s;
            -webkit-tap-highlight-color: transparent;
        }
        .reload svg:hover {
            color: #bb7a00 !important;
        }
        .reload svg:active {
            scale: 0.9;
            color: #bb7a00 !important;
        }
    }

    .compare {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #0000007a;
        border: solid 1px #21212179;
        overflow: auto;

        .message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            color: gray;
        }
        .players {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1em;

            .column {
                position: relative;
                display: grid;
                grid-row-gap: 0.5em;
                min-height: 100%;
                height: max-content;
                border-radius: 10px;
                margin: 1em 0.5em;
                padding-bottom: 0.5em;
                border: solid 1px gray;

                .team {
                    position: absolute;
                    top: 0.4em;
                    left: 0.5em;
                    height: 1.5em;
                    width: 1.5em;
                }

                .close {
                    font-size: 0.9em;
                    position: absolute;
                    top: 0.6em;
                    right: 0.6em;
                    cursor: pointer;
                    transition: all 0.3s;
                }
                .close:active {
                    scale: 0.9;
                }
                .player {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 7em;
                    width: 7em;
                    margin-top: 1em;
                    padding: 0.4em;

                    img {
                        height: 3.5em;
                        border-radius: 100px;
                    }
                    .vertical-offset {
                        height: 8em;
                        padding: 0.4em;
                    }
                    .name {
                        height: 3em;
                        display: flex;
                        align-items: center;
                        margin-top: 0.2em;
                        font-size: 0.9em;
                        font-family: Roboto Condensed, Roboto, Arial;
                        font-weight: 700;
                    }
                    .info {
                        font-size: 0.65em;
                        font-weight: 300;
                        color: white;
                    }
                }

                .scope {
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: #0000007a;
                        border-radius: 1em;
                        border-color: #808080d6;
                        padding: 0.2em 0;
                        font-family: Roboto Condensed, Roboto, Arial;
                        font-weight: 700;
                        font-size: 0.8em;
                        color: #e0e0e0;
                        text-indent: 0.5em;
                        cursor: pointer;
                    }
                }

                .stat {
                    display: flex;
                    flex-direction: column;
                    height: 3em;
                    width: 7em;
                    padding: 0.4em;

                    .heading {
                        font-family: Roboto Condensed, Roboto, Arial;
                        font-size: 0.9em;
                        font-weight: 700;
                        margin-bottom: 0.3em;
                    }
                    .subtitle {
                        font-size: 0.6em;
                        font-weight: 300;
                        color: #cdcdcd;
                        margin-top: 0.1em;
                    }
                }
            }
        }
    }
}
